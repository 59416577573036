#category_list_desktop_navigation_container {
}
#category_list_mobile_navigation_container {
}

@media only screen and (max-width: 700px) {
    #category_list_desktop_navigation_container {
        display: none;
    }
    #category_list_mobile_navigation_container {
        display: flex;
    }
}
