#subscription_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0;
}

#subscription_title {
    font-size: 36px;
    font-weight: 600;
    margin: 15px 0 30px 0;
    text-align: center;
}
#subscription_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}
.subscription_box_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    box-shadow: 3px 3px 7px 2px rgba(0, 0, 0, 0.05);
    border: 1px solid #e8e4ee;
    width: 300px;
    padding: 10px;
}

.subscription_box_title {
    width: 90%;
    font-size: 28px;
    font-weight: 600;
    border-bottom: 3px solid #8e75bd;
    padding-bottom: 15px;
    margin-bottom: 15px;
    text-align: center;
}
.subscription_original_price {
    height: 20px;
    font-size: 20px;
    margin-right: 30px;
    text-decoration: line-through;
}
.subscription_pricing_row {
    font-size: 22px;
    font-weight: 500;
    padding: 0 15px 15px 15px;
}
#subscription_price_monthly {
    font-size: 48px;
    font-weight: 600;
    color: #ca318b;
}
#subscription_price_yearly {
    font-size: 48px;
    font-weight: 600;
    color: #53358b;
}
.subscription_item_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 15px;
}
.subscription_buttons {
    width: 150px;
    height: 20px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #8e75bd;
    color: white;
    transition: box-shadow ease-in 0.5s;
    margin-top: auto;
}
.subscription_buttons:hover {
    box-shadow: 3px 3px 7px 2px #3b00aa;
}
@media only screen and (max-width: 700px) {
    #subscription_grid {
        grid-template-columns: 1fr;
    }
}
