#search_result_title {
    padding-top: 30px;
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: 600;
    color: #14052f;
}

#search_result_filter_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 25px;
}

.search_result_filters {
    font-weight: 600;
    padding: 5px 10px;
    margin-right: 10px;
    vertical-align: middle;
    font-weight: 600;
    color: #8134bd;
    border: 1px solid #d9d9d9;
    /* box-shadow: 2px 2px 3px 2px rgb(0 0 0 / 25%); */
    border-radius: 5px;
    cursor: pointer;
}

#search_result_search_for {
    font-size: 16px;
    font-weight: 600;
    margin-right: 18px;
}

.search_result_sub_title {
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: 600;
    color: #14052f;
}

#search_result_category_list_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.search_result_category_list {
    margin: 5px;
    color: #757477;
    font-weight: 600;
    border: 1px solid #d9d9d9;
    box-shadow: 2px 2px 3px 2px rgb(0 0 0 / 25%);
    border-radius: 10px;
    padding: 5px 10px;
}

.search_result_category_list:hover {
    color: #8134bd;
}

.search_result_list_container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
}

#search_result_ad {
    padding-top: 30px;
}

@media only screen and (max-width: 900px) {
    .search_result_list_container {
        grid-template-columns: repeat(2, 1fr);
    }
}