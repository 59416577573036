#adplinad_container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: #F9EEF4;
    color: white;
    width: 100%;
    height: 130px;
    margin: 20px 0;
    cursor: pointer;
    user-select: none;
}
#adplinad_container > img{
    height: 100%;
}
#adplinad_right_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
#adplinad_right_container > div:nth-child(1){
    font-size: 20px;
    font-weight: 600;
    color: #CA318B;
}
#adplinad_right_container > div:nth-child(2){
    font-size: 16px;
    font-weight: 600;
    color: black;
}

