#earning_mypaymentlink_wrapper {
    display: none;
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
}
#earning_mypaymentlink_box_wrapper {
    background-color: #fff;
    width: 80%;
    max-width: 400px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 43px 43px 43px;
}
#earning_mypaymentlink_close_button {
    margin-left: auto;
    font-size: 25px;
    cursor: pointer;
    transition: transform 0.5s ease-in;
}
#earning_mypaymentlink_close_button:hover {
    transform: rotate(180deg);
}
#earning_mypaymentlink_title {
    font-size: 28px;
    font-weight: bold;
    color: #53358b;
    width: 90%;
}
#earning_mypaymentlink_linkWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0px 20px 0px;
    border: 1px solid #bdb8c6;
    box-sizing: border-box;
    border-radius: 10px;
    width: 90%;
    padding: 10px 23px;
    cursor: pointer;
}
#earning_mypaymentlink_link {
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
#earning_mypaymentlink_copy_button {
    font-size: 25px;
}
#earning_mypaymentlink_infoWrapper {
    display: flex;
    width: 90%;
}
#earning_mypaymentlink_info_icon {
    font-size: 20px;
    margin-right: 10px;
}
#earning_mypaymentlink_copy_message {
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: fixed;
    bottom: 20px;
    left: calc(50% - 80px);
    width: 160px;
    height: 30px;
    border-radius: 5px;
    background-color: #000000ad;
    color: white;
    margin: auto;
    user-select: none;
    z-index: 5;
}

@media only screen and (max-width: 900px) {
    #earning_mypaymentlink_box_wrapper {
        padding: 20px 15px 30px 15px;
    }
}
