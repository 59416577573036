#post_likes_space_between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
}
#post_likes_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0;
}
#post_likes_stars {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #FFD600;
}
#post_likes_people {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 10px;
}
#post_likes_people_number {
    padding: 0 5px;
}
#post_likes_report {
    display: flex;
    flex-direction: row;
    align-items: center;
    /*border: 2px solid #9016f0;*/
    color: #670940;
    padding: 5px;
    font-size: 16px;
    cursor: pointer;
}
