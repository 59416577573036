#post_tag_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.post_tag_item_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 15px;
    margin-right: 15px;
    margin-bottom: 10px;
    color: black;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #BDB8C6;
}
