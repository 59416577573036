#post_mobile_about{
    border: 2px solid #bdb8c6;
    box-shadow: 2px 2px 0 0 rgb(0 0 0/.15), inset 0 0 2px 2px rgb(0 0 0/.15);
    padding: 10px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
}
#post_mobile_about_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
}
#post_mobile_about_picture_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #2b031a;
    color: white;
    font-size: 50px;
    margin: 15px 0;
    box-shadow: 4px 4px rgb(0 0 0/15%);
}
#post_mobile_about_title {
    font-size: 20px;
    font-weight: 600;
    margin: 0 15px;
    /*text-shadow: 3px 3px rgb(0 0 0/15%);*/
}
#post_mobile_phone_display{
    height: 21px;
}
#post_mobile_phone_display .form-control {
    border: 0px solid black;
    padding-left: 0px;
    width: 100%;
    height: 100%;
    line-height: 21px;
    font-size: 16px;
    pointer-events: none;
}
#post_mobile_phone_display .react-tel-input{
     height: 21px;
}
#post_mobile_phone_display .flag-dropdown {
    display: none;
}
#post_mobile_about_link {
    text-decoration: underline;
}
#post_mobile_about_message {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 40px;
    border-radius: 5px;
    background-color: purple;
    color: white;
    margin: 10px 0 20px 0;
    cursor: pointer;
}
