#body_wrapper {
    width: 1100px;
    margin: auto;
    min-height: 75vh;
    user-select: none;
}

a {
    text-decoration: none;
    color: inherit;
}

input {
    font-size: 16px;
}

::-webkit-scrollbar {
    height: 0px;
    width: 8px;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: white;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgb(187 113 244 /0.2);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:horizontal {
    background: #000;
    border-radius: 10px;
}

#footerDistance {
    border-top: 1px solid #e4e4e4;
    margin-top: 20px;
}

/* under 1200px width*/

@media only screen and (max-width: 1200px) {
    #body_wrapper {
        width: 90%;
    }
}

/* 
under 1400px width
@media only screen and (max-width: 1200px) {
    #body_wrapper {
        width: 90%;
    }
} */
