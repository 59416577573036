#uploadSingleFile_upload_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border: 1px dashed #d9d9d9;
    background-color: #fafafa;
}

#uploadSingleFile_input {
    display: none;
}

#uploadSingleFile_upload_image {
    width: 100%;
    image-orientation: none;
}
#create_edit_upload_single_file_message {
    color: red;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.uploadSingleFile_upload_lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.uploadSingleFile_upload_lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #a946d2;
    border-radius: 50%;
    animation: uploadSingleFile_upload_lds-ring 1.2s
        cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #a946d2 transparent transparent transparent;
}
.uploadSingleFile_upload_lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.uploadSingleFile_upload_lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.uploadSingleFile_upload_lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes uploadSingleFile_upload_lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 700px) {
    #uploadSingleFile_upload_button {
        width: 60%;
        margin: auto;
    }
}

@media only screen and (max-width: 500px) {
    #uploadSingleFile_upload_button {
        width: 80%;
        margin: auto;
    }
}
