#home_categories_container {
    padding: 30px 0;
}
#home_categories_list_container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* display: flex;
    flex-wrap: nowrap; */
    /* flex-direction: row;
    justify-content: space-around; */
}

#home_categories_text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    font-size: 35px;
    font-weight: 700;
    margin: 15px 0;
}
#home_categories_text :nth-child(2) {
    font-size: 16px;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
}

#home_categories_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 20px;
    width: 250px;
    height: 200px;
    border: 1px solid #BDB8C6;
    /*background-color: #4167b3d6;*/
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
}
#home_categories_list :nth-child(1) {
    width: 250px;
    height: 150px;
}
#home_categories_list :nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 50px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}
.home_categories_list_image_container {
    width: 250px;
    height: 150px;
    overflow: hidden;
}
.home_categories_list_image {
    transition: transform ease-in 0.3s;
}

/* under 1400px width*/
@media only screen and (max-width: 1400px) {
    #home_categories_list {
        width: 200px;
        height: 160px;
    }
    #home_categories_list :nth-child(1) {
        width: 200px;
        height: 110px;
    }
    #home_categories_list :nth-child(2) {
        width: 200px;
        height: 50px;
    }
}

/* under 1400px width*/
@media only screen and (max-width: 900px) {
    /* #home_categories_list_container {
        flex-wrap: wrap;
    } */
    #home_categories_list_container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    #home_categories_list {
        width: 300px;
        height: 200px;
    }
    #home_categories_list :nth-child(1) {
        width: 300px;
        height: 150px;
    }
    #home_categories_list :nth-child(2) {
        width: 300px;
        height: 50px;
    }
}

/* under 1400px width*/
@media only screen and (max-width: 700px) {
    #home_categories_list_container {
        display: grid;
        grid-template-columns: 1fr;
    }
}
