#create_category_title_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    padding: 15px 0;
}

#create_category_title_container>div:nth-child(2) {
    margin: 0 10px;
}

#create_category_title {
    font-size: 24px;
    font-weight: 600;
}

#create_category_add_category {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #bdb8c6;
    border-radius: 5px;
    color: #bdb8c6;
    padding: 5px 10px;
    cursor: pointer;
}

#create_category_add_category:hover {
    color: #402e61;
}

#create_category_group_container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: baseline;
    list-style: none;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

#create_category_group_container>li {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    margin: 5px;
}

#create_category_list_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    list-style: none;
    grid-gap: 5px;
}

.create_category_list {
    /* list-style: none;
    width: 100%;
    height: 100px;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid black; */
    padding: 5px 0;
    cursor: pointer;
}

.create_category_list:hover {
    font-weight: 600;
    /* background-color: gray; */
}

.create_category_list_link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
}

#create_category_add_category_container {
    display: none;
    top: 0;
    left: 0;
    background-color: #80808082;
    z-index: 99;
    width: 100%;
    height: 100%;
    position: fixed;
    justify-content: center;
    align-items: center;
}

#create_category_add_category_box {
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 30px;
}

#create_category_add_category_close {
    cursor: pointer;
    margin-left: auto;
}

#create_category_add_category_close:hover {
    transition: .5s ease-in;
    transform: rotate(180deg);
}

#create_category_add_category_title {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 30px;
}

#create_category_add_category_input {
    border: 1px solid #BDB8C6;
    border-radius: 10px;
    min-width: 300px;
    height: 40px;
    outline: none;
    margin-bottom: 30px;
}

#create_category_add_category_button {
    background: linear-gradient(94.39deg, rgba(202, 49, 139, .5), #53358b 98.8%);
    color: white;
    width: 120px;
    height: 40px;
    border: 0;
    margin: auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
}

#create_wrapper_mobile {
    display: none;
}

@media only screen and (max-width: 1100px) {
    #create_category_list_container {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media only screen and (max-width: 800px) {
    #create_wrapper_mobile {
        display: flex;
        flex-direction: column;
    }
    #create_mobile_back_button {
        margin: 15px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        font-weight: 600;
    }
    #create_group_container_mobile {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        list-style: none;
        flex-wrap: wrap;
    }
    #create_group_container_mobile>li {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        user-select: none;
        margin: 5px;
        gap: 20px;
    }
    #create_wrapper_desktop {
        display: none;
    }
    #create_category_list_container {
        grid-template-columns: 1fr 1fr;
    }
}