#main_categories_list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #bdb8c6;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
}

.main_categories_list_image_container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.main_categories_list_image {
    width: 100%;
    height: 100%;
    transform: scale(1);
}
.main_categories_list_name {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-weight: 600;
    color: white;
}
