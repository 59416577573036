#create_edit_navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
}
#create_edit_navigation_link {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #a946d2;
    color: white;
    padding: 5px;
    border-radius: 5px;
}
#create_edit_navigation_link:hover {
    font-weight: 600;
}
#create_edit_category_name {
    border: 1px solid #a946d2;
    background-color: #a946d2;
    color: white;
    border-radius: 5px;
    padding: 5px;
}

.create_edit_title {
    font-size: 25px;
    font-weight: 600;
    margin: 10px 0;
}
#create_edit_buttons_container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 15px 0;
}
#create_edit_buttons_container > button {
    width: 100px;
    height: 30px;
    cursor: pointer;
}
#create_edit_buttons_container > button:hover {
    background-color: gray;
}
#create_edit_err_message {
    color: red;
    text-align: center;
    font-weight: 600;
}
