#messageListMobile_left_container {
    height: 100%;
    width: 100vw;
    margin-left: -50vw;
    left: 50%;
    position: relative;
}

#messageList_left_title {
    font-size: 20px;
    font-weight: 600;
    color: #14052F;
}

#messageList_left_list_container {
    list-style-type: none;
}

#messageList_left_list_container>li {
    display: grid;
    grid-template-columns: 40px 1fr;
    /*border-bottom: 1px solid black;*/
    grid-gap: 5px;
    padding: 10px 5px;
    border-radius: 10px;
    margin: 10px 5px;
    cursor: pointer;
}

.messenger_messageList_selectedColor {
    background-color: #f1e6ed !important;
}

.messenger_messageList_selectedColorOverflow {
    background-color: #f1e6ed8c !important;
}

.messenger_messageList_icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #2B031A;
    color: white;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.messenger_messageList_description {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 0 5px;
}

.messenger_messageList_description>div:nth-child(even) {
    text-align: right;
}

.messenger_messageList_to_name {
    font-size: 16px;
    font-weight: 600;
}

.messenger_messageList_message {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
}

.messenger_messageList_message_overflow {
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    height: 100%;
    background-color: #ffffffc9;
}

.messenger_messageList_date {
    color: #A7A3A3;
    font-size: 12px;
}

.messenger_messageList_read {
    text-align: right;
    background-color: #8E75BD;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: auto 5px auto auto;
}