.create_edit_box_container {
    display: flex;
    flex-direction: column;
}
#create_edit_upload_input {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 10px;
}
#mainImage {
    width: 100%;
    margin: auto;
    /* width: 300px; */
    /* height: 300px; */
}
#subImage {
    border: 1px dashed #d9d9d9;
}
.mainImageUpload {
    width: 100%;
    height: 100%;
}
.mainImageUpload > div {
    width: 100% !important;
    height: 100% !important;
}
.create_edit_picture_title {
    display: flex;
    flex-direction: row;
}
.create_edit_picture_info_container {
    position: relative;
    display: flex;
    align-items: center;
}
.create_edit_picture_info_container:hover .create_edit_picture_info {
    display: flex;
}
.create_edit_picture_info {
    display: none;
    position: absolute;
    top: -60px;
    left: 0;
    width: 150px;
    font-size: 16px;
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
}
/* under 1400px width*/
@media only screen and (max-width: 700px) {
    #create_edit_upload_input {
        grid-template-columns: 1fr;
    }
    #mainImage {
        width: 80%;
    }
}
