#setting_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

#setting_navigation_container {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0 20px 0 0;
}

.setting_navigation_selected {
    color: #53358B;
    border-bottom: 3px solid #53358B;
    padding: 10px 0 7px 0 !important;
}

#setting_navigation_container>a {
    padding: 10px 0;
    margin: 0 10px;
    font-size: 22px;
    font-weight: 600;
    cursor: pointer;
    width: fit-content;
}

#setting_container>div {
    width: 100%;
}

@media only screen and (max-width: 700px) {
    #setting_container {
        flex-direction: column;
    }
    #setting_navigation_container {
        display: none;
        /*flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 0;*/
    }
}