#category_list_top_post_container_id {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
}
#category_list_top_post_title {
    font-size: 28px;
    font-weight: 600;
    color: #53358b;
    margin: 10px 0;
}

@media only screen and (max-width: 900px) {
    #category_list_top_post_container_id {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* @media only screen and (max-width: 700px) {
    #category_list_top_post_container_id {
        grid-template-columns: repeat(1, 1fr);
    }
} */
