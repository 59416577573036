#messenger_messageDetailMobile_container {
    display: flex;
    flex-direction: column;
    /* min-height: calc(100% - 125px);
    max-height: calc(100% - 125px); */
    /* height: calc(100vh - 125px); */
    /* Fallback for browsers that do not support Custom Properties */
    /* height: calc(var(--vh, 1vh) * 100 - 125px); */
    /* min-height: calc(100vh - 125px); */
    /* min-height: -webkit-fill-available; */
    /* padding: 10px; */
    /*box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.25);*/
    /* border-radius: 15px; */
    /* width: 100vw;
    margin-left: -50vw;
    left: 50%;
    position: relative; */
    position: fixed;
    background-color: white;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    width: 100%;
    height: calc(100% - 70px);
    /* height: calc(var(--vh, 1vh) * 100); */
    overflow: hidden;
}

#messenger_messageDetailMobile_top_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #d2d2d2;
}

#messenger_messageDetailMobile_top {
    font-size: 20px;
    font-weight: 600;
    color: #14052F;
}

#messenger_messageDetailMobile_top_right {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    color: #53358B;
}

#messenger_messageDetailMobile_top_right>svg {
    cursor: pointer;
}

#messenger_messageDetailMobile_board {
    height: 100%;
    /* background-image: url(/images/logo_icon.svg); */
    /* background-size: 150px 150px; */
    /* background-repeat: no-repeat;
    background-position: center; */
    list-style: none;
    overflow-y: scroll;
    position: relative;
}

/*
#messenger_messageDetailMobile_board > img {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 150px;
    height: 150px;
    margin: auto;
    opacity: 0.3;
}
*/

.messenger_messageDetail_board_list {
    background-color: white;
    padding: 15px;
    margin: 10px;
    max-width: 70%;
    word-wrap: break-word;
}

.messenger_messageDetail_board_list_receive {
    text-align: left;
    width: fit-content;
    border-radius: 15px 15px 15px 0;
    background-color: #E9E7EC;
}

.messenger_messageDetail_board_list_send {
    text-align: right;
    margin-left: auto;
    width: fit-content;
    border-radius: 15px 15px 0 15px;
    background-color: #8E75BD;
    color: white;
}

.messenger_messageDetail_board_list_post {
    width: fit-content;
    text-align: center;
    font-weight: 600;
    margin: auto;
    cursor: pointer;
}

.messenger_messageDetail_board_list_post>img {
    width: 150px;
    box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, .25);
    border-radius: 15px;
}

#messenger_messageDetailMobile_input {
    height: 70px;
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: #F7F4FC;
    border-radius: 10px;
    position: fixed;
    bottom: 0;
}

#messenger_messageDetailMobile_input>input {
    width: 100%;
    line-height: 70px;
    background-color: transparent;
    border: 0;
    outline: none;
    padding: 0 10px;
    font-size: 24px
}

.messenger_messageDetailMobile_send_button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #53358B;
    width: 70px;
    height: 70px;
    cursor: pointer;
}