#uploadMultipleFiles_file_display {
    display: none;
    height: 100%;
    width: 100%;
}
.uploadMultipleFiles_file_display {
    position: relative;
    width: 100%;
}
.uploadMultipleFiles_file_display_image {
    width: 100%;
    height: 100%;
    image-orientation: none;
}
.uploadMultipleFiles_file_display_hover {
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);

    z-index: 1;
    cursor: pointer;
}
.uploadMultipleFiles_file_display:hover
    .uploadMultipleFiles_file_display_hover {
    display: flex;
}
#uploadMultipleFiles_container_layout {
    width: 100%;
    /* height: 100%; */
    border: 1px dashed #d9d9d9;
}
#uploadMultipleFiles_container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
#uploadMultipleFiles_upload_button_container {
    width: 100%;
    height: 100%;
    order: 999;
}
#uploadMultipleFiles_upload_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: #fafafa;
    border: dashed 1px #d9d9d9;
}
#uploadMultipleFiles_input {
    display: none;
}
#create_edit_upload_multiple_files_message {
    color: red;
}
