.full_width {
    position: absolute;
    left: 0;
    width: 100%;
}
.normal_width {
    width: 1400px;
    margin: auto;
}
/* under 1400px width*/
@media only screen and (max-width: 1400px) {
    .normal_width {
        width: 90%;
    }
}
