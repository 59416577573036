/* #full_notification_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f1e6ed;
    margin-left: -50vw;
    left: 50%;
    width: 100vw;
    height: 90vh;
    position: relative;
} */
#relative_notification_box_wrapper {
    width: 70%;
    max-width: 300px;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}
#relative_notification_title {
    font-weight: bold;
    font-size: 32px;
    color: #513287;
    margin: 21px 0;
}
#relative_notification_message {
    color: #595757;
    font-size: 20px;
}
#relative_notification_info {
    margin: 10px 0 18px 0;
    color: #595757;
    display: flex;
    flex-direction: row;
    font-size: 12px;
}
#relative_notification_info_icon {
    margin-right: 5px;
}
#relative_button {
    width: 150px;
    max-width: 140px;
    padding: 10px;
    margin: 15px auto;
    background: linear-gradient(94.18deg, #d798bd 5.59%, #53358b 103.4%);
    border-radius: 10px;
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
}
