#messenger_container {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-gap: 10px;
    height: 100%;
    /* height: calc(100vh - 75px); */
    position: relative;
    padding: 15px 0;
}

#messenger_select_message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#messenger_select_message > img {
    width: 150px;
    height: 150px;
}

#messageListMobile_left_container {
    display: none;
}

#messenger_messageDetailMobile_container {
    display: none;
}
#messenger_select_a_conversation {
    color: #555;
    font-size: 30px;
}

@media only screen and (max-width: 900px) {
    #messenger_container {
        grid-template-columns: 1fr;
        padding: 0;
        /* height: calc(100vh - 125px); */
    }
    #messageListMobile_left_container {
        display: block;
    }
    #messenger_messageDetailMobile_container {
        display: flex;
    }
    #messageList_left_container {
        display: none;
    }
    #messenger_messageDetail_container {
        display: none;
    }
    #messenger_select_message {
        display: none;
    }
}
