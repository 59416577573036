#browse_trending2_list_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: scroll;
    margin-top: 30px;
    transition: margin-top .3s ease-in;
    scroll-behavior: smooth;
}

#browse_trending2_list_controller_left, #browse_trending2_list_controller_right {
    position: absolute;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: linear-gradient(94.39deg, rgba(202, 49, 139, .5), #53358b 98.8%);
    color: white;
}

#browse_trending2_list_controller_left {
    left: -15px;
}

#browse_trending2_list_controller_right {
    right: -15px;
}

.browse_trending2_list {
    width: 200px;
    margin-right: 25px;
}

.browse_trending2_list:last-child {
    margin-right: 0;
}

.browse_trending2_list_img {
    height: 150px;
    width: 200px;
    box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, .25);
    background-color: #bdb8c6;
    border-radius: 5px;
    margin-bottom: 15px;
}

.browse_trending2_user_info {
    display: grid;
    grid-template-columns: 1fr 20px;
    grid-gap: 4px;
    margin-bottom: 8px;
    line-height: 20px;
}

.browse_trending2_user_info_left {
    display: flex;
    flex-direction: row;
    gap: 4px;
    overflow: hidden;
}

.browse_trending2_user_info_left_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    line-height: 20px;
    color: #ffffff;
    background-color: #2b031a;
}

.browse_trending2_user_info_left_name {
    white-space: nowrap;
    overflow: hidden;
}

.browse_trending2_user_info_like {
    margin: auto;
}

.browse_trending2_title {
    line-height: 1em;
    height: 2.2em;
    font-size: 14px;
    overflow: hidden;
    margin-bottom: 8px;
}

.browse_trending2_price_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /*grid-gap: 9px;*/
    font-size: 14px;
    text-align: center;
}

.browse_trending2_price_container>div:nth-child(1) {
    color: #d9bfee;
    /*border-radius: 5px;*/
    border: .5px solid #bdb8c6;
}

.browse_trending2_price_container>div:nth-child(2) {
    color: #bb71f4;
    /*border-radius: 5px;*/
    border: .5px solid #bdb8c6;
    border-left: 0;
    border-right: 0;
}

.browse_trending2_price_container>div:nth-child(3) {
    color: #8134bd;
    /*border-radius: 5px;*/
    border: .5px solid #bdb8c6;
}

#browse_trending2_placeholder_container {
    width: 100%;
    height: 333px;
    background-color: #e6e6e6;
}