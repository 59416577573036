#home_howitworks_container {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#home_howitworks_title {
    font-size: 32px;
    font-weight: 600;
    margin: 25px 0;
    margin-right: auto;
    color: #14052F;
}

#home_howitworks_detail_container {
    /*display: flex;
    flex-direction: row;
    justify-content: space-around;*/
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px 10%;
    width: 90%;
}

.home_howitworks_detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border: 1px solid #BDB8C6;
    box-sizing: border-box;
    border-radius: 10px;
    /*background-color: #fedffe;*/
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 40px;
}

.home_howitworks_detail_title {
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    height: 45px;
    text-align: center;
    padding-bottom: 15px;
    margin-bottom: 15px;
    color: #14052F;
}

#home_howitworks_detail_title_1 {
    border-bottom: 3px solid #CA318B;
}

#home_howitworks_detail_title_2 {
    border-bottom: 3px solid #53358B
}

.home_howitworks_detail_list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 5px 0;
    font-size: 17px;
    font-weight: 400;
}

.home_howitworks_detail_list_number {
    font-size: 32px;
    font-weight: 600;
    color: #CA318B;
    margin-right: 15px;
    margin-bottom: auto;
}

.home_howitworks_detail_list_right>div:nth-child(1) {
    font-size: 20px;
}

.home_howitworks_detail_list_right>div:nth-child(2) {
    font-size: 16px;
}

.home_howitworks_detail_list :nth-child(2) {
    /* width: 300px; */
}

@media only screen and (max-width: 1000px) {}

@media only screen and (max-width: 700px) {
    #home_howitworks_detail_container {
        grid-template-columns: 1fr;
    }
}