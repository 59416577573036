#payment_background_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f1e6ed;
    margin-left: -50vw;
    left: 50%;
    width: 100vw;
    height: 90vh;
    position: relative;
}

#payment_form_wrapper {
    width: 500px;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.1);
    background-color: white;
}

#payment_form_recipient {
    font-weight: bold;
    font-size: 20px;
    color: #74668f;
}
#payment_form_recipient_name {
    color: #341e5e;
}

.payment_form_title {
    margin: 15px 0;
    color: #6b6a6a;
}

.payment_form_input {
    width: 100%;
    padding: 10px;
    border: 1px solid #bdb8c6;
    box-sizing: border-box;
    border-radius: 10px;
    /* margin-bottom: 45px; */
}
#payment_form_buttons_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.payment_form_button {
    width: 217px;
    max-width: 150px;
    padding: 15px 20px;
    margin: 15px 0px;
    font-weight: 600;
    display: flex;
    font-size: 16px;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
}
#payment_form_button_back {
    color: #595757;
    border: 1px solid #6d4895;
    box-sizing: border-box;
    align-items: center;
}
#payment_form_button_back_icon {
    position: absolute;
    left: 10px;
}

#payment_form_button_pay {
    color: white;
    background: linear-gradient(94.18deg, #d798bd 5.59%, #53358b 103.4%);
}

#payment_form_error_message {
    color: #fa755a;
}
#payment_form_tos {
    color: #c4c4c4;
    margin: 15px 0;
}
@media only screen and (max-width: 700px) {
    #payment_form_wrapper {
        width: 85%;
        padding: 3%;
    }
}

@media only screen and (max-width: 700px) {
    #payment_form_wrapper {
        width: 85%;
        padding: 3%;
    }
    .payment_form_button {
        padding: 3%;
    }
}
