#categories_title_container {
    display: flex;
    flex-direction: column;
    padding: 15px 0;
}

#categories_title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
}

#categories_group_container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: baseline;
    list-style: none;
    flex-wrap: wrap;
}

#categories_group_container>li {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    margin: 5px;
}

#categories_list_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    list-style: none;
    grid-gap: 5px;
}

.categories_list {
    padding: 5px 0;
    cursor: pointer;
}

.categories_list:hover {
    font-weight: 600;
}

#categories_wrapper_mobile {
    display: none;
}

@media only screen and (max-width: 800px) {
    #categories_list_container {
        grid-template-columns: 1fr 1fr;
    }
    #categories_wrapper_mobile {
        display: flex;
        flex-direction: column;
    }
    #categories_mobile_back_button {
        margin: 15px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        font-weight: 600;
    }
    #categories_group_container_mobile {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        list-style: none;
        flex-wrap: wrap;
    }
    #categories_group_container_mobile>li {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        user-select: none;
        margin: 5px;
        gap: 20px;
    }
    #categories_wrapper_desktop {
        display: none;
    }
}