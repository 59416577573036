#post_pictures_bigger_image {
    width: 100%;
    height: auto;
}
#post_pictures_bottom_container {
    display: flex;
    flex-direction: row;
    position: relative;
    user-select: none;
    /* background-color: gainsboro; */
}
#post_pictures_smaller_images_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    overflow-x: hidden;
    scroll-behavior: smooth;
}
#post_pictures_smaller_images_container:hover {
    overflow-x: scroll;
}
.post_pictures_smaller_images {
    width: 20%;
    height: auto;
    margin-right: 5px;
    cursor: pointer;
    align-self: flex-start;
    border: 3px solid transparent;
}
#post_pictures_smaller_image_border {
    border: 3px solid purple;
}
.post_pictures_smaller_image_controller {
    width: 5%;
    display: none;
    height: 100%;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.6);
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
#post_pictures_smaller_image_controller_right {
    right: 0;
}
@media only screen and (max-width: 700px) {
    #post_pictures_bottom_container {
    }
    #post_pictures_smaller_images_container {
        overflow-x: scroll;
    }
    .post_pictures_smaller_image_controller {
        display: none;
    }
}
