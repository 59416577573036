#post_desktop_about {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #bdb8c6;
    border-radius: 5px;
    /*box-shadow: 2px 2px 0 0 rgb(0 0 0 / 0.15),
        0 0 2px 2px rgb(0 0 0 / 0.15) inset;*/
}
#post_desktop_about_title {
    font-size: 24px;
    font-weight: 600;
    margin-top: 10px;
    /*text-shadow: 3px 3px rgb(0 0 0 / 15%);*/
}
#post_desktop_about_picture_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #2b031a;
    color: white;
    font-size: 64px;
    margin: 15px 0;
    box-shadow: 4px 4px rgb(0 0 0 / 15%);
}
.post_desktop_about_grid {
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 40px;
    text-align: left;
    padding: 10px 0;
    border-bottom: 1px solid #e8e4ee;
    cursor: pointer;
}
#post_desktop_about_phone {
    pointer-events: none;
}
#post_desktop_about_phone .form-control {
    border: 0px solid black;
    padding-left: 0px;
    width: 100%;
    height: 100%;
    line-height: 21px;
    font-size: 16px;
    pointer-events: none;
}
#post_desktop_about_phone .react-tel-input {
    height: 21px;
}
#post_desktop_about_phone .flag-dropdown {
    display: none;
}

#post_desktop_about_phone_icon {
    height: 100%;
    display: flex;
    align-items: center;
}
#post_desktop_about_link {
    text-decoration: underline;
    width: 100%;
}
#post_desktop_about_message {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 40px;
    border-radius: 5px;
    background-color: purple;
    color: white;
    margin: 10px 0 20px 0;
    cursor: pointer;
}
