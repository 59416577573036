.list_card_link_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.list_card_link_image_container {
    border-radius: 15px;
    overflow: hidden;
    background-color: #bdb8c6;
    box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.25);
}
.list_card_link_image {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #bdb8c6;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.25);
    transform: translateX(-100%);
    /*transform: rotate3d(0, 1, 0, 180deg);*/
    transition: transform 0.5s ease;
}
.list_card_link_bottom_container {
    /* display: grid;
    grid-template-columns: 20px 2fr 3fr; */
    display: flex;
    flex-direction: column;
    grid-gap: 3px;
    margin: 5px 0;
    /* align-items: center; */
    width: 100%;
}
.list_card_link_bottom_first_line {
    display: grid;
    grid-template-columns: 1fr 20px;
    line-height: 21px;
    grid-gap: 4px;
    margin: 8px 0;
}
.list_card_link_bottom_user_container {
    display: flex;
    flex-direction: row;
    grid-gap: 4px;
    gap: 4px;
    overflow: hidden;
}
.list_card_link_bottom_first_character {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-shrink: 0;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    line-height: 20px;
    color: #ffffff;
    background-color: #2b031a;
}
.list_card_link_bottom_name {
    font-size: 16px;
    width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
}
.list_card_link_title {
    line-height: 1em;
    height: 2.2em;
    font-size: 14px;
    overflow: hidden;
    margin-bottom: 8px;
}
.list_card_link_bottom_price_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    font-size: 12px;
    text-align: center;
}
.list_card_link_bottom_price_container > div {
    border-radius: 5px;
    border: 0.5px solid #bdb8c6;
}
.list_card_link_bottom_price_container > div:nth-child(1) {
    color: #d9bfee;
}
.list_card_link_bottom_price_container > div:nth-child(2) {
    color: #bb71f4;
}
.list_card_link_bottom_price_container > div:nth-child(3) {
    color: #8134bd;
}
