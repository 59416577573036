#loginForm {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: rgba(50, 51, 56, 0.8);
}

#loginBox {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    height: 600px;
    background-color: white;
    border-radius: 5px;
    user-select: none;
}

#loginBoxClose {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}

#loginBoxClose:hover {
    transition: transform 0.2s ease-in;
    transform: rotate(90deg);
}

#loginText {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 32px;
    font-weight: 700;
    padding: 20px 0 10px 0;
    width: 320px;
}

#loginSocial {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 150px;
}

.loginFacebook,
.loginGoogle,
.loginApple {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 320px;
    height: 40px;
    font-size: 15px;
    cursor: pointer;
    border: 1px solid #bdb8c6;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: white;
}

.loginFacebook {
    background-color: #4167b3;
    color: white;
}

.loginFacebook:hover {
    background-color: #4167b3d6;
}

.loginGoogle {
    color: black;
}

.loginApple {
    color: black;
}

.loginForm_loginBtns {
    display: flex;
    flex-direction: row;
}

.loginCompanies {
    margin-left: 15px;
    text-align: left;
}

.loginGoogle:hover,
.loginApple:hover {
    color: black;
    background-color: #ffff;
}

#loginForm_line {
    width: 320px;
    height: 12px;
    text-align: center;
    border-bottom: 1px solid #6b6a6a !important;
}

#loginForm_or {
    font-size: 12px;
    color: #6b6a6a;
    padding: 10px;
    line-height: 24px;
    background-color: white;
}

#loginForm_form {
    margin: 20px 0 0 0;
    display: flex;
    flex-direction: column;
    width: 320px;
}

#loginForm_form > input {
    height: 40px;
    margin: 5px 0;
    border: 1px solid #bdb8c6;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0 10px;
    outline: none;
}

#loginForm_form > input::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #bdb8c6;
}

#loginForm_form > input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #bdb8c6;
    opacity: 1;
}

#loginForm_form > input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #bdb8c6;
    opacity: 1;
}

#loginForm_form > input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bdb8c6;
}

#loginForm_submitBtn {
    font-size: 100%;
    font-weight: 700;
    border-width: 0 !important;
    color: white;
    cursor: pointer;
    background: linear-gradient(94.18deg, #d798bd 5.59%, #53358b 103.4%);
    border-radius: 10px;
}

#loginForm_submitBtn:hover {
    transition: background ease-in 0.5s;
    /* background-color: #da81ff !important; */
    background: linear-gradient(
        94.18deg,
        #53358b 5.59%,
        #d798bd 103.4%
    ) !important;
}

.loginForm_nav_container {
    margin-bottom: 5px;
}

#loginForm_nav_container_forgotten {
    text-align: right;
    font-size: 14px;
}

.loginForm_nav {
    color: #8e75bd;
    cursor: pointer;
}

.loginForm_message {
    color: red;
}

#loginForm_verify_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0 5px 0;
}

#loginForm_verify_title {
    font-size: 20px;
    font-weight: 600;
    margin: 15px 0;
}

#loginForm_verify_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 350px;
    margin: 10px 0;
}

#loginForm_verify_email {
    font-weight: 600;
}

#loginForm_sign_in_box_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #6b6a6a;
    padding: 10px 0;
    margin: 10px 0;
}

#loginForm_sign_in_box {
    color: #8e75bd;
    font-weight: 600;
    width: 320px;
    height: 40px;
    margin: 5px 0;
    border: 1px solid #8e75bd;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.loginForm_invisible {
    display: none;
}
.abcRioButtonContentWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 320px;
    height: 40px !important;
    font-size: 15px;
    cursor: pointer;
    border: 1px solid #bdb8c6;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: white;
}
.abcRioButton,
.abcRioButton:hover {
    box-shadow: none !important;
}
.abcRioButtonLightBlue {
    width: 100% !important;
    height: 40px !important;
}
