#mainBackgroundImage {
    /* background-image: url('/images/main1.jpg'); */
    position: relative;
    top: 0;
    width: 100%;
    height: 80vh;
    z-index: -1;
    background-color: #e8e8ea;
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: center; */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
#mainBackTextContainer {
    width: 70%;
    font-size: 40px;
    font-weight: 700;
    word-break: break-word;
    font-family: sofia-pro, Roboto, Helvetica, Arial, sans-serif;
}
#mainBackText {
    color: white;
    background-color: #323338;
    padding: 20px 0 20px 20px;
}

#home_bodyWrapper {
    width: 1400px;
    margin: auto;
}

/* under 1400px width*/
@media only screen and (max-width: 1400px) {
    #home_bodyWrapper {
        width: 90%;
    }
}
