#adplin_police {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #3a3a3ade;
}

#adplin_disable_request_wrapper {
    position: relative;
    padding: 20px;
    background: linear-gradient( 94.39deg, rgba(202, 49, 139, .5), #53358b 98.8%);
    color: white;
    max-width: 80%;
    user-select: none;
}

#adplin_disable_request_wrapper>div {
    margin-bottom: 10px;
}

#adplin_disable_request_wrapper_close_wrapper {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: ease-in-out transform 0.3s;
    user-select: none;
}

#adplin_disable_request_wrapper_close {
    color: black;
}

#adplin_disable_request_wrapper_close_wrapper:hover {
    transform: rotate(90deg);
}