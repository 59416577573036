#full_notification_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f1e6ed;
    margin-left: -50vw;
    left: 50%;
    width: 100vw;
    height: 90vh;
    position: relative;
}
#full_notification_box_wrapper {
    width: 70%;
    max-width: 300px;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#full_notification_title {
    font-size: 32px;
    color: #513287;
    margin: 21px 0;
}
