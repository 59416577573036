#category_list_desktop_navigation_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    padding: 10px 0 30px 0;
}
#category_list_desktop_navigation_container_title {
    font-size: 32px;
    font-weight: 600;
    white-space: nowrap;
}
#category_list_desktop_navigation_other_list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: scroll;
    scroll-behavior: smooth;
}
.category_list_desktop_navigation_other_list_item {
    margin: 5px 15px;
    white-space: nowrap;
    border-bottom: 2px solid white;
    cursor: pointer;
    user-select: none;
}
.category_list_desktop_navigation_other_list_item:hover {
    border-bottom: 2px solid black;
}

.category_list_desktop_navigation_other_list_buttons {
    cursor: pointer;
    user-select: none;
    margin: auto 0 5px 0;
    height: 100%;
}
#category_list_desktop_navigation_other_list_buttons_left {
    visibility: hidden;
}
