#post_report_container {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
}

#post_report_box_container {
    background-color: white;
    width: 80%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
}
#post_report_title {
    font-size: 24px;
    font-weight: 600;
}
#post_report_textarea {
    width: 80%;
    height: 200px;
    font-size: 16px;
}
#post_report_box_buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    margin-top: 15px;
}
#post_report_box_buttons > div {
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: purple;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}
#post_report_close_button {
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: purple;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    margin-top: 15px;
}
