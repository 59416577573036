#checkout_form_container {
    width: 500px;
    height: 300px;
    margin: auto;
    padding: 25px;
    background-color: #d0d0d0d0;
}
#checkout_card_row {
    display: flex;
    flex-direction: row;
}
#checkout_form_stripe_container {
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 2px 2px gray;
}
#checkout_message {
    color: red;
}
