#home_introduction_container {
    position: relative;
    width: 100vw;
    min-height: 300px;
    padding: 40px 0;
    margin-left: -50vw;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(94.18deg, #D798BD 5.59%, #53358B 103.4%);
}
#home_introduction_title{
    width: 1400px;
    color: white;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 30px;
}
#home_introduction_text_container{
    width: 1400px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px 5%;
}
#home_introduction_text{
    background-color: white;
    border-radius: 10px;
    padding: 40px 20px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    text-align: center;
}
/* under 1400px width*/
@media only screen and (max-width: 1400px) {
    #home_introduction_title {
        width: 90%;
    }
    #home_introduction_text_container{
        width: 90%;
    }
}

@media only screen and (max-width: 900px) {
    #home_introduction_text_container {
        grid-template-columns: 1fr;
    }
}
