#post_price {
    width: 90%;
    padding: 5%;
    border: 1px solid rgb(189 184 198 / 0.1);
    border-radius: 5px;
    margin: 15px 0;
}

#post_price_title {
    font-size: 20px;
    /*border-bottom: 2px solid #bdb8c6;*/
    width: 100%;
    padding: 5px 0;
    color: #341e5e;
}

#post_price_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    width: 100%;
    margin: 10px 0;
}

.post_price_box {
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.post_price_box>div:nth-child(1) {
    position: relative;
    color: white;
    border-radius: 10px;
    padding: 10px 0;
    width: 90%;
    text-align: center;
    margin: 10px;
    /* font-size: 28px; */
}

.post_price_box>div:nth-child(2) {
    font-size: auto;
    font-weight: 600;
    color: black;
    padding: 5px;
    margin: 5px;
}

/* .post_price_info_icon {
    position: absolute;
    top: 2px;
    right: 2px;
    cursor: pointer;
    font-size: 12px;
} */

.post_price_box:nth-child(1) {
    border: 2px solid #d9bfee;
}

.post_price_box:nth-child(2) {
    border: 2px solid #bb71f4;
}

.post_price_box:nth-child(3) {
    border: 2px solid #8134bd;
}

.post_price_box:nth-child(1)>div:nth-child(1) {
    background-color: #d9bfee;
}

.post_price_box:nth-child(2)>div:nth-child(1) {
    background-color: #bb71f4;
}

.post_price_box:nth-child(3)>div:nth-child(1) {
    background-color: #8134bd;
}

/* under 700px width*/

@media only screen and (max-width: 700px) {
    .post_price_box>div:nth-child(1) {
        padding: 10px;
        margin: 5px;
        width: 70%;
    }
    .post_price_box>div:nth-child(2) {
        font-size: 100%;
    }
}