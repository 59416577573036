#post_grid_container {
    display: grid;
    /* grid-template-columns: 3fr 2fr; */
    grid-template-columns: 1fr 350px;
    grid-gap: 50px;
    position: relative;
    padding: 15px 0;
}

#post_left_part {
    overflow: hidden;
}

#post_grid_container > div {
    /* width: 100%; */
}

#post_desktop_container {
    position: sticky;
    top: 75px;
    height: 100vh;
    /* max-height: 90vh; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#post_Square_container {
    width: 100%;
    margin-top: 15px;
}

#post_mobile_container {
    display: none;
}

#post_copy_message {
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: fixed;
    bottom: 20px;
    left: calc(50% - 80px);
    width: 160px;
    height: 30px;
    border-radius: 5px;
    background-color: #000000ad;
    color: white;
    margin: auto;
    user-select: none;
    z-index: 5;
}

.post_payment_button_container {
    width: 150px;
    max-width: 150px;
    padding: 20px;
    margin: 15px auto;
    background: linear-gradient(94.18deg, #d798bd 5.59%, #53358b 103.4%);
    border-radius: 10px;
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    /* box-shadow: 2px 2px 0 0 #8134bc; */
}

@media only screen and (max-width: 900px) {
    #post_grid_container {
        grid-template-columns: 1fr;
    }
    #post_desktop_container {
        display: none;
    }
    #post_mobile_container {
        display: flex;
        flex-direction: column;
    }
}
