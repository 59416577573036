#footer_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 25px 0;
    width: 1400px;
    margin: auto;
}

#footer_left_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    color: #c4c4c4;
}

#footer_left_container > a {
    margin-right: 30px;
}

#footer_right_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
    grid-gap: 5px;
    font-size: 12px;
    color: #c4c4c4;
    align-items: center;
    text-align: center;
}

#footer_language_select {
    border: 0;
    font-size: 20px;
    cursor: pointer;
    user-select: none;
    width: fit-content;
    color: #a7a3a3;
    /* appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%; */
}

/* under 1400px width*/

@media only screen and (max-width: 1400px) {
    #footer_container {
        width: 90%;
    }
}

@media only screen and (max-width: 700px) {
    #footer_container {
        width: 90%;
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }
    #footer_right_container {
        grid-template-columns: repeat(5, 1fr);
    }
}
