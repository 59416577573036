#browse_top_container {
    /* display: flex;
    flex-direction: row; */
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 6px;
    width: 100%;
    padding-top: 15px;
}
#browse_top_right_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 6px;
    width: 100%;
}
.browse_title {
    font-size: 28px;
    font-weight: 600;
    padding: 20px 0;
    color: #14052F;
}

@media only screen and (max-width: 700px) {
    #browse_top_container {
        display: grid;
        grid-template-columns: 1fr;
    }
}
