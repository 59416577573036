#language_setting_container {
    padding: 20px 0;
}

.language_setting_row_container {
    display: grid;
    grid-template-columns: 200px 3fr 1fr;
    padding: 10px 0;
}

.language_setting_container:nth-child(1) {
    border-bottom: 1px solid #BDB8C6;
}

.language_setting_title {
    font-size: 20px;
}

.language_setting_description {
    color: #6b6a6a;
    font-size: 16px;
}

#language_setting_select {
    height: 24px;
    border: 0;
    font-size: 20px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
}

#language_setting_available_languages {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 5px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(25, auto);
    list-style: none;
    padding: 0;
    text-align: left;
}

.language_setting_available_languages_true {
    font-weight: 600;
    color: #14052F;
    cursor: pointer;
    user-select: none;
}

.language_setting_available_languages_false {
    color: #A7A3A3;
    cursor: pointer;
    user-select: none;
}

@media only screen and (max-width: 900px) {
    #language_setting_available_languages {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(30, auto);
    }
}

@media only screen and (max-width: 700px) {
    .language_setting_row_container {
        grid-template-columns: 2fr 1fr;
    }
    .language_setting_description {
        order: 3;
    }
    #language_setting_available_languages {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(40, auto);
    }
}