#component_message_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 400px;
    /* height: 100%; */
}

#component_message_container>img {
    /* transition: ease-in 1s; */
    /* animation: mymove 2s linear infinite; */
}

/* @keyframes mymove {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
} */

#component_message_container>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #53358B;
    background-color: #ffffffba;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
}

@media only screen and (max-width: 700px) {
    #component_message_container>div {
        font-size: 20px;
    }
}