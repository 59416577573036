#browse_categories_container {
    width: 100%;
    margin: 15px 0;
}
#browse_categories_title_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
#browse_categories_see_all {
    text-align: end;
    text-decoration: underline;
    cursor: pointer;
}
#browse_popular_categories {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
}
#browse_popular_categories > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1200px) {
    #browse_popular_categories {
        grid-template-columns: repeat(4, 1fr);
    }
    /* #browse_popular_categories > a:nth-child(9),
    #browse_popular_categories > a:nth-child(10) {
        display: none;
    } */
}
@media only screen and (max-width: 700px) {
    #browse_popular_categories {
        grid-template-columns: repeat(2, 1fr);
    }
}
