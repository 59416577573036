#recently_viewed_title {
    padding: 26px 0;
    color: #341e5e;
    font-size: 32px;
}
#recently_viewed_top_container {
    padding: 15px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}
#recently_viewed_top_container > div:nth-child(1) {
    font-size: 32px;
    color: #341e5e;
}
#recently_viewed_top_container > div:nth-child(2) {
    background-color: #8e75bd;
    color: white;
    width: 70px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
}
.recently_viewed_grid_container {
    display: grid;
    grid-template-columns: 133px 3fr 1fr 1fr 2fr;
    text-align: center;
}
.recently_viewed_grid_container > div {
    display: flex;
    justify-content: center;
    align-items: center;
}
#recently_viewed_list_header {
    font-weight: 600;
    padding: 5px 0;
    border-top: 3px solid #bdb8c6;
    border-bottom: 3px solid #bdb8c6;
}
#recently_viewed_list_header_mobile {
    position: relative;
    display: none;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    user-select: none;
    padding: 5px 0;
    border-top: 3px solid #bdb8c6;
    border-bottom: 3px solid #bdb8c6;
}
#recently_viewed_list_header_desktop {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    user-select: none;
}
#recently_viewed_list_header_mobile_sort,
#recently_viewed_list_header_desktop_sort {
    margin: 0 10px;
}
#recently_viewed_list_header_mobile_menu {
    position: absolute;
    display: none;
    top: 40px;
    left: 0;
}
#recently_viewed_list_header_desktop_menu {
    position: absolute;
    display: none;
    top: 30px;
    left: 0;
}
#recently_viewed_list_header_desktop_menu {
    display: none;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    padding: 5px 0;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.13);
    border-radius: 3px;
    list-style: none;
    z-index: 2;
}
#recently_viewed_list_header_desktop_menu > li {
    padding: 5px 15px;
    width: 100%;
    text-align: center;
}
.recently_viewed_list {
    grid-template-columns: 133px 1fr !important;
    padding: 15px 0;
    border-bottom: 3px solid #e8e4ee;
}
.recently_viewed_grid_container2 {
    display: grid !important;
    grid-template-columns: 3fr 1fr 1fr;
}
.recently_viewed_grid_container2 > div {
    cursor: pointer;
}
.recently_viewed_grid_container2 > div:last-child {
    cursor: auto;
}
.recently_viewed_images {
    width: 100%;
    height: auto;
    cursor: pointer;
}
.recently_viewed_list_buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.recently_viewed_list_buttons > div {
    padding: 3px 10px;
    margin: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #8e75bd;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
}
#recently_viewed_delete_post {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgb(0 0 0 / 0.7);
}
#recently_viewed_delete_post_box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 500px;
    height: 300px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.13);
}
#recently_viewed_delete_post_box > div:nth-child(1) {
    font-size: 26px;
    font-weight: 600;
}
#recently_viewed_delete_post_box > div:last-child {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
#recently_viewed_delete_post_box > div:last-child > div {
    margin: 10px;
    padding: 5px 10px;
    border: 1px solid black;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
}

@media only screen and (max-width: 700px) {
    #recently_viewed_list_header {
        display: none;
    }
    #recently_viewed_list_header_mobile {
        display: flex;
    }
    #recently_viewed_list_header_mobile_menu {
        display: none;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background-color: white;
        padding: 5px 0;
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.13);
        border-radius: 3px;
        list-style: none;
        z-index: 2;
    }
    #recently_viewed_list_header_mobile_menu > li {
        padding: 5px 15px;
        width: 100%;
        text-align: center;
    }
    .recently_viewed_grid_container2 {
        grid-template-columns: 1fr;
        text-align: left;
        padding: 0 10px;
    }
    .recently_viewed_grid_container2 > div {
        padding: 5px 0;
    }
    .recently_viewed_grid_container2 > div:nth-child(1) {
        font-size: 20px;
        font-weight: 600;
    }
    .recently_viewed_list_buttons {
        justify-content: flex-end;
    }
    #recently_viewed_delete_post_box {
        width: 80%;
        height: 200px;
    }
}
