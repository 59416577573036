.create_edit_pricing_box_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0;
    flex-wrap: wrap;
}
.create_edit_pricing_box_container > div:nth-child(1) {
    width: 100px;
}
.create_edit_pricing_box_container > div:nth-child(1) {
    font-weight: 600;
}

.create_edit_pricing_info_container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media only screen and (max-width: 700px) {
    .header_mobileMenu {
        display: flex;
        justify-content: center;
    }
    .header_regularMenu {
        display: none;
    }
}
