#post_review_title_container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #bdb8c6;
    margin-bottom: 20px;
    user-select: none;
}
#post_review_title {
    font-size: 20px;
    font-weight: 600;
}
#post_review_sort_button {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    color: #4f4959;
}
#post_review_sort_button_sort_by {
    font-weight: 500;
    margin-right: 10px;
}
#post_review_sort_button_icon {
    display: flex;
    margin-left: 10px;
}
#post_review_sort_container {
    display: none;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    color: #48454f;
    position: absolute;
    right: 5px;
    top: 30px;
    text-decoration: none;
    list-style: none;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.13);
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
}
#post_review_sort_container > li {
    padding: 5px;
}
.post_review_list_container {
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-gap: 10px;
    border-bottom: 1px solid #bdb8c6;
    margin-bottom: 20px;
}
.post_review_image_container {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #2b031a;
    color: white;
    font-size: 28px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}
#post_review_logged_in_top {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
}
.post_review_list_top {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
}
.post_review_list_top > div {
    margin: 10px;
}
#post_review_logged_in_top > div {
    margin: 10px;
}
.post_review_date {
    font-size: 12px;
}
#post_review_buttons_container {
    margin-left: auto !important;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 1fr 1fr;
}
.post_review_buttons {
    margin-left: auto !important;
    display: flex;
    justify-content: center;
    align-items: center !important;
    background-color: #8e75bd;
    color: white;
    border-radius: 5px;
    width: 58px;
    height: 24px;
    line-height: 24px;
    cursor: pointer;
    user-select: none;
}
#post_review_input {
    width: calc(100% - 28px);
    height: 100px;
    margin: 0 10px;
    font-size: 16px;
    border: 1px solid rgb(0 0 0 / 0.2);
    resize: none;
}
.post_review_text_container {
    font-size: 16px;
    margin: 0 10px;
    min-height: 100px;
}
.post_review_star_container {
    color: #ffd600;
}
#post_review_star_container_edit {
    cursor: pointer;
}
#post_review_no_review {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
    padding-bottom: 20px;
    border-bottom: 1px solid #bdb8c6;
    margin-bottom: 15px;
}
.post_review_list_report {
    margin-left: auto !important;
    color: #670940;
    cursor: pointer;
}
@media only screen and (max-width: 700px) {
    #post_review_logged_in_top {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }
    .post_review_list_top {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }
    .post_review_list_top > div {
        margin: 5px;
    }
    .post_review_date {
        margin-left: auto !important;
    }
    #post_review_logged_in_top > div {
        margin: 5px;
    }
    #post_review_logged_in_date {
        margin-left: auto !important;
    }
    #post_review_input {
        margin: 0 5px;
    }
    .post_review_text_container {
        margin: 0 5px;
    }
}
