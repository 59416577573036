#earning_title_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
#earning_title_wrapper_non_enabled {
    display: flex;
    flex-direction: column;
}
#earning_title {
    font-size: 32px;
    color: #341e5e;
    padding: 26px 0;
}
#earning_title_buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 30px;
}

#earning_title_buttons > div {
    cursor: pointer;
}
#earning_balance_table_wrapper {
    width: 100%;
    margin: auto;
    border: 3px solid #e5e5e5;
    border-radius: 10px;
    border-spacing: 0px;
    /*box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);*/
}
#earning_balance_table_wrapper th {
    color: #4f4f4f;
    text-align: left;
    padding: 48px 39px 0 20px;
    font-weight: bold;
    font-size: 20px;
}
#earning_balance_table_wrapper td {
    text-align: left;
    padding: 10px 20px 48px 39px;
    font-size: 26px;
    font-weight: bold;
}
#earning_balance_table_wrapper td:nth-child(1) {
    color: #341e5e;
}
#earning_balance_table_wrapper td:nth-child(2) {
    color: #9282b0;
}
#earning_no_payment {
    display: table-row;
    width: 100%;
    text-align: center;
}
#earning_no_payment td {
    padding: 100px 0 !important;
    font-size: 28px !important;
    color: #53358b !important;
    font-weight: bold !important;
    cursor: auto !important;
}
#earning_payment_title {
    /* font-weight: bold;
    font-size: 32px;
    padding: 12px 0; */
    font-size: 32px;
    color: #341e5e;
    padding: 26px 0;
}
#earning_table {
    width: 100%;
    /*border-spacing: 2px 4px;*/
    border-spacing: 0px;
    cursor: pointer;
    border-collapse: collapse;
}
#earning_table tr {
    border-top: 3px solid #e8e4ee;
}

#earning_table th {
    border-top: 3px solid #bdb8c6;
    border-bottom: 3px solid #bdb8c6;
}
#earning_table td {
    padding: 30px 10px;
    color: #4f4f4f;
}

.earning_payment_wrapper {
    padding: 10px;
    margin: 2px 0;
    /*background-color: #f6f6f8;*/
}
.earning_payment_wrapper td {
    text-align: center;
    white-space: nowrap;
}
.earning_payment_email_address {
    overflow: hidden;
    white-space: pre-wrap !important;
    word-break: break-all;
}
.earning_payment_amount {
    color: #341e5d !important;
    font-weight: bold;
}
.earning_payment_bad_amount {
    color: #e91e63 !important;
    font-weight: bold;
}
.earning_payment_detail_wrapper {
    display: none;
    border-top: 0px !important;
}
.earning_payment_detail_title {
    font-weight: bold;
}
.earning_payment_detail_wrapper td {
    text-align: left;
    padding: 10px 0px !important;
}
.earning_payment_detail_refund_wrapper {
    border-left: 2px solid red;
}
.earning_payment_detail_refund_title {
    padding-left: 25px;
}
.earning_payment_refund_info_title {
    font-weight: bold;
    color: #e91e63 !important;
}

.earning_payment_detail_button {
    transition: 0.3s ease-in;
}

#earning_more_charges_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 92px;
    height: 32px;
    padding: 8px 12px;
    margin: 20px auto;
    border: 1px solid #6e6e6e;
    border-radius: 5px;
    color: #6e6e6e;
    cursor: pointer;
}

@media only screen and (max-width: 700px) {
    #earning_title_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }
    #earning_title_buttons {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        grid-gap: 15px;
        margin-bottom: 20px;
    }
}
