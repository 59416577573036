#headerContainer {
    position: fixed;
    top: 0px;
    width: 100%;
    /* background-color: rgba(255, 255, 255, 0);
    color: #323338; */
    color: white;
    /* background-image: linear-gradient(to right, black, rgba(173, 117, 177, 0.3)); */
    /* background-color: rgb(173 117 177 /1); */
    /* background-color: rgba(50, 51, 56, 1); */
    background-color: #ffffff;
    border-bottom: 1px solid #e8e4ee;
    z-index: 99;
}

#headerNavContainer {
    height: 74px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* width: 80%; */
    width: 1400px;
    margin: auto;
    font-size: 15px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    position: relative;
}
#headerNavLeft_container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

#headerNavLeft {
    display: flex;
    text-decoration: none;
    color: #6b6a6a;
    font-size: 35px;
}
#headerNav_search_input_desktop_container {
    display: grid;
    grid-template-columns: 1fr 20px;
    /* display: flex;
    flex-direction: row; */
    align-items: center;
    border: 1px solid #8e75bd;
    border-radius: 5px;
    padding: 0 10px;
    width: 100%;
    max-width: 400px;
    cursor: pointer;
    /* transition: 0.3s ease-in; */
}
#headerNav_search_input_desktop {
    outline: none;
    border: 0;
    width: 100%;
}
#headerNav_search_input_desktop_icon {
    color: black;
    height: 100%;
    cursor: pointer;
    width: 20px;
    height: 20px;
}
#headerNav_search_input_mobile_icon {
    display: none;
}
#headerNavLink {
    text-decoration: none;
    color: #6b6a6a;
}

#headerNavRight {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    list-style: none;
    user-select: none;
}

.headerNavRight_Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
    font-size: 20px;
    font-weight: 600;
    border-radius: 50%;
    background-color: #2b031a;
}

#headerNavRight_menu_container_position {
    position: fixed;
    /*top: 75px;*/
    top: 0;
    width: 100%;
    height: 100%;
    /*height: 0px;*/
    width: 100%;

    z-index: 99;
}

#headerNavRight_menu_container {
    position: relative;
    top: 75px;
    width: 1400px;
    /*height: 0px; */
    height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

#headerNavRight_menu {
    position: relative;
    right: 0;
    margin: 2px 5px;
    padding: 0 15px;
    list-style: none;
    width: 168px;
    color: #6b6a6a;
    background-color: #ffffff;
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: none;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

#headerNavRight_menu > li,
#headerNavRight_menu > a {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 10px 5px;
    cursor: pointer;
    border-bottom: 0.5px solid #d8d8d8;
}

#headerNavRight_menu > li:hover,
#headerNavRight_menu > a:hover {
    font-weight: 600;
}

#headerShowMenu {
    transition: transform ease-in 0.3s;
}

.headerNav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    min-width: 80px;
    color: #6b6a6a;
    text-decoration: none;
    cursor: pointer;
    margin: 0 10px;
    font-size: 15px;
    font-weight: 600;
}

.headerNavUnderBar:hover {
    border-bottom: 2px solid #6b6a6a;
}

#headerLogIn {
    color: #8e75bd;
}
#headerLogIn:hover {
    border-bottom: 2px solid #6b6a6a;
}

#headerSignUp {
    /* border: 2px solid #6b6a6a; */
    border-radius: 4px;
    color: white;
    background-color: #53358b;
}

#headerSignUp:hover {
    /* background-color: #6b6a6a; */

    background-color: #8e75bd;
    transition: background-color ease-in 0.3s;
}

#headerSignOut {
    cursor: pointer;
}

#headerSignOut:hover {
    cursor: pointer;
    border-bottom: 2px solid #6b6a6a;
}

#headerNavRight_menu_container_position {
    display: none;
}

.header_mobileMenu {
    display: none;
    cursor: pointer;
}

.header_mobileMenu_line {
    display: none;
}

.header_mobileMenu_user {
    display: none;
}

.header_mobileMenu_only {
    display: none !important;
}
#header_search_mobile {
    display: none;
    flex-direction: row;
    align-items: center;
    position: fixed;
    top: 74px;
    width: 100%;
    height: 50px;
    background-color: white;
    z-index: 3;
    border-bottom: 1px solid #e8e4ee;
}
#header_search_mobile_search_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid black;
    border-radius: 10px;
    width: 90%;
    margin: auto;
}
#header_serach_mobile_input_icon {
    margin-left: 5px;
}
#header_serach_mobile_input {
    width: 100%;
    border: 0;
    border-radius: 10px;
    height: 25px;
    outline: none;
}
/* under 1400px width*/

@media only screen and (max-width: 1400px) {
    #headerNavContainer,
    #headerNavRight_menu_container {
        width: 90%;
    }
}

@media only screen and (max-width: 700px) {
    #headerNav_search_input_desktop_container {
        display: none;
    }
    #headerNav_search_input_mobile_icon {
        display: flex;
        position: absolute;
        right: 0;
        height: 30px;
        width: 30px;
        color: black;
        margin: auto;
        cursor: pointer;
    }
    #headerNavRight_menu_container_position {
        height: 100%;
    }
    #headerNavRight_menu_container {
        width: 100%;
        height: 100%;
    }
    #headerNavRight_menu {
        width: 100%;
        height: 100%;
        justify-content: flex-start;
        margin: 0;
        padding: 0;
        overflow: scroll;
        height: calc(100% - 75px);
        box-shadow: 0 0 0 0 rgb(0 0 0 / 10%);
        border-radius: 0px;
    }
    #headerNavRight_menu > li,
    #headerNavRight_menu > a {
        justify-content: flex-start;
        width: 90%;
        height: auto;
        font-size: 20px;
        font-weight: 600;
        color: #6b6a6a;
        border: 0;
    }
    .header_mobileMenu {
        display: flex;
        justify-content: center;
    }
    .header_mobileMenu_line {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #d8d8d8;
        margin: 10px 0;
    }
    .header_mobileMenu_user {
        display: flex;
        width: 90%;
        padding: 10px 0;
        justify-content: flex-start;
        font-size: 20px;
        font-weight: 600;
    }
    #header_mobileMenu_user_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 43px;
        height: 43px;
        border-radius: 50%;
        background-color: #2b031a;
        color: white;
    }
    .header_mobileMenu_user > div:nth-child(2) {
        color: #14052f;
        margin: auto 10px;
    }
    .header_regularMenu {
        display: none;
    }
    #headerNavLeft,
    #headerNavLeft_container {
        margin: auto;
    }
    #headerNavRight {
        position: absolute;
        margin: auto 0;
    }
    .header_mobileMenu_only {
        display: flex !important;
    }
    .header_desktopMenu_only {
        display: none !important;
    }
}
