#create_success_text_container {
    position: relative;
    width: 100vw;
    min-height: 300px;
    margin-left: -50vw;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
        95.53deg,
        rgba(196, 196, 196, 0.8) 0%,
        rgba(83, 53, 139, 0.8) 0.01%,
        rgba(86, 53, 139, 0.8) 0.02%,
        rgba(89, 53, 139, 0.8) 0.03%,
        rgba(191, 49, 139, 0.8) 99.96%,
        rgba(196, 49, 139, 0.473886) 99.97%,
        rgba(191, 49, 139, 0.488222) 99.98%,
        rgba(202, 49, 139, 0.456) 99.99%,
        rgba(196, 196, 196, 0) 100%
    );
}
#create_success_title {
    font-size: 60px;
    font-weight: 800;
    color: white;
    z-index: 2;
    text-align: center;
}
#create_success_button {
    font-size: 40px;
    color: white;
    z-index: 2;
    padding: 15px;
    border: 4px solid white;
    cursor: pointer;
    margin: 10px;
}
#create_success_image {
    position: absolute;
    left: 10%;
    width: auto;
    height: 100%;
}

@media only screen and (max-width: 700px) {
    #create_success_title {
        font-size: 30px;
    }
    #create_success_button {
        font-size: 20px;
    }
}
