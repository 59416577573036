#browse_search_container {
    position: relative;
    margin-bottom: 100px;
    margin-left: -50vw;
    left: 50%;
    width: 100vw;
    background: linear-gradient(
        94.39deg,
        rgba(202, 49, 139, 0.5) 0%,
        #53358b 98.8%
    );
}
#browse_search_relative_width {
    width: 1100px;
    margin: auto;
}
#browse_search_introduction {
    text-align: center;
    font-size: 35px;
    font-weight: 600;
    color: white;
    margin: auto;
    padding: 80px 0 35px 0;
}
#browse_search_input_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    max-width: 600px;
    margin: auto;
    background-color: white;
    padding: 0 15px;
}
#browse_search_input {
    display: flex;
    width: 100%;
    height: 40px;
    margin: auto;
    outline: none;
    border: 0;
    font-size: 18px;
}
#browse_search_search_icon {
    width: 25px;
    height: 40px;
    cursor: pointer;
}
#browse_search_search_icon:hover {
    color: #8e75bd;
}
#browse_search_categories_box {
    display: flex;
    width: 90%;
    height: fit-content;
    margin: auto;
    border-radius: 50px;
    background-color: white;
    bottom: -50px;
    min-height: 100px;
    padding: 0;
    align-items: center;
    position: relative;
}
#browse_search_categories_container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    /* display: flex;
    flex-direction: row;
    justify-content: space-around; */
    grid-gap: 10px;
    margin: auto;
    text-align: center;
    width: 90%;
    padding: 0;
    list-style: none;
}
#browse_search_categories_container > li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    cursor: pointer;
    user-select: none;
    /* white-space: nowrap; */
    width: fit-content;
    height: 100%;
    margin: auto;
}

.browse_search_categories_icon {
    width: 50px;
    height: 50px;
    padding-top: 20px;
    transition: 0.3s ease-in-out;
}

@media only screen and (max-width: 1200px) {
    #browse_search_relative_width {
        width: 90%;
    }
}

@media only screen and (max-width: 900px) {
    #browse_search_categories_box {
        min-height: 200px;
        bottom: -100px;
        margin-top: -50px;
    }
    #browse_search_categories_container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 500px) {
    #browse_search_categories_box {
        width: 100%;	
    }
}
