#browse_trending_week_container {
    display: flex;
    flex-direction: column;
}
#browse_trending_weekly_image_container {
    /* width: 391px;
    height: 303px; */

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
#browse_trending_weekly_image_container > img {
    width: 100%;
    height: auto;
    object-fit: cover;
}
#browse_trending_weekly_description_container {
    height: 100%;
    color: white;
    background-color: #323339;
    user-select: none;
}
#browse_trending_weekly_description_container > div:nth-child(1) {
    font-weight: 600;
    font-size: 18px;
}
#browse_trending_weekly_description_container > div:nth-child(2) {
    font-size: 14px;
}
