#loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
}

#loading-container > img {
    width: 150px;
    height: auto;
}

#loading-container > div {
    color: gray;
}

#loading-container > img {
    /* transition: ease-in 1s; */
    animation: mymove 2s linear infinite;
}

@keyframes mymove {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
