.browse_main_hover_link {
    height: auto;
}
.card_container {
    display: flex;
    width: 100%;
    height: auto;
    position: relative;
}
.card_container > img {
    width: 100%;
    height: auto;
    object-fit: cover;
    align-self: flex-start;
}
.main_card_container {
    display: none;
    position: absolute;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    cursor: pointer;
    width: 100%;
    height: 100%;
    user-select: none;
    text-align: center;
}
.main_card_container > div:nth-child(1) {
    font-weight: 600;
    font-size: 18px;
}
.card_container:hover .main_card_container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}
