#post_title_container {
    font-size: 28px;
    font-weight: 700;
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 20px;
}
#post_title_share {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    margin: auto 0;
}
