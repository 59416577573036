#account_title {
    font-size: 30px;
    padding: 20px 0;
    border-bottom: 1px solid black;
}

.account_main_row {
    display: grid;
    grid-template-columns: 150px 1fr 70px;
    grid-gap: 0 10px;
    align-items: center;
    padding: 10px 0;
    height: 40px;
    border-top: 1px solid gray;
}

.account_edit_row {
    display: grid;
    grid-template-columns: 150px 1fr 70px;
    grid-gap: 10px;
    align-items: center;
    padding-bottom: 10px;
}

.account_edit_row>input {
    width: 100%;
    border: 1px solid #BDB8C6;
    border-radius: 5px;
    height: 30px;
    box-sizing: border-box;
}

.account_edit_row>div {
    /* padding: 10px 0; */
}

.account_main_row:first-child {
    border: 0;
}

.account_main_row>div:nth-child(1) {
    font-size: 20px;
    font-weight: 400;
    width: 100px;
}

.account_main_row_edit {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    border: 1px solid #D9BFEE;
    border-radius: 5px;
    width: 60px;
    height: 25px;
    color: #A7A3A3;
}

.account_main_row_edit_cancel {
    background-color: #8E75BD;
    color: white;
}

#account_main_onPassword {
    width: 50%;
    margin: auto;
    padding: 10px 0;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
}

.account_main_editing {
    width: 100;
    margin: auto;
    padding: 10px 0;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
}

#account_main_onPassword>div, .account_main_editing>div {
    font-weight: 600;
}

#account_main_onPassword>input, .account_main_editing>input {
    height: 25px;
}

.account_edit_save_button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    border: 1px solid #D9BFEE;
    border-radius: 5px;
    width: 60px;
    height: 25px;
    background-color: #8E75BD;
    color: white;
    padding: 0 !important;
}

.account_main_buttons {
    width: 90%;
    margin: auto;
    padding: 10px 0;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
}

.account_main_buttons>div {
    width: 100px;
    height: 40px;
    color: white;
    background-color: purple;
    border-radius: 5px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.account_main_buttons>div:hover {
    box-shadow: black 2px 2px;
}

.account_main_error_message {
    text-align: center;
    color: red;
}

#account_main_chooseName_container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

#account_main_chooseName_title {
    font-weight: 600;
}

.account_main_editing_input {
    width: 100%;
    border: 1px solid #BDB8C6;
    border-radius: 5px;
    height: 30px;
    box-sizing: border-box;
}

#account_main_chooseName {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    user-select: none;
}

#account_main_editing_full_name {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    width: 100%;
}

#account_main_editing_full_name>input {
    /*width: 100% !important;*/
}

#account_main_businessName, #account_main_fullName {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 100px;
    text-align: center;
    color: #7b7b7b;
    background-color: #F7F4FC;
    border: 1px solid #341E5E;
    border-radius: 5px;
    cursor: pointer;
}

#account_main_onPhoneChange {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#account_main_onPhoneChange>div {
    font-weight: 600;
    text-align: center;
    margin: 10px;
}

#account_main_onWebsiteChange {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#account_main_onWebsiteChange>div {
    font-weight: 600;
    text-align: center;
    margin: 10px;
}

#account_main_onWebsiteChange>input {
    width: 90%;
    max-width: 400px;
    height: 25px;
}

#account_main_phone_display {
    pointer-events: none;
}

.account_edit_row .form-control {
    border: 1px solid #BDB8C6;
    border-radius: 5px;
    height: 30px;
    width: 100% !important;
    /* text-align: center; */
}

#account_main_phone_display .flag-dropdown {
    display: none;
}

.account_main_row .form-control {
    margin-left: 0;
    padding-left: 0;
    border: 0;
}

#account_main_phone_display .form-control {
    width: 100%;
}

@media only screen and (max-width: 700px) {
    .account_main_row {
        grid-template-columns: 100px 1fr 70px;
    }
    .account_edit_row {
        grid-template-columns: 100px 1fr 70px;
    }
    #account_main_onPassword {
        width: 90%;
    }
    .account_main_editing {
        grid-template-columns: 1fr 1fr;
    }
    #account_main_editing_mobile_1 {
        order: 1;
    }
    #account_main_editing_mobile_2 {
        order: 2;
    }
    #account_main_editing_mobile_3 {
        order: 3;
    }
    #account_main_editing_mobile_4 {
        order: 4;
    }
    #account_main_editing_mobile_5 {
        order: 5;
    }
    #account_main_editing_mobile_6 {
        /* order: 6; */
    }
    #account_main_chooseName_container {
        flex-direction: column;
    }
}
